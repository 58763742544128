import { en as sitcEn } from '@amzn/sitc-frontend/lang';

export const en = {
  ...sitcEn,
  'app.product_name': 'Data Mesh Explorer',
  'app.header.product_identifier': 'Data Mesh Explorer',
  'app.error.not_found.actionLabel': 'Back to Homepage',
  'app.error.access_denied.actionLabel': 'Ask for Access',
  'app.error.schema.action_label': 'Retry',
  'app.loading.schema': 'Loading Schema...',
  'home.card.apollo_sandbox.title': 'ISD-G (Apollo Sandbox)',
  'home.card.apollo_sandbox.description': 'Explore Domain Authorities through ISD-G using the Apollo Sandbox.',
  'home.card.graphiql.title': 'ISD-G (GraphiQL)',
  'home.card.graphiql.description': 'Explore Domain Authorities through ISD-G using the GraphiQL IDE.',
  'navigation.home': 'Home',
};
